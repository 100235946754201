.primary-btn {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-family: 'Poppins' !important;
}

.primary-hollow-btn {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  font-family: 'Poppins' !important;
  background: white;
}

.delete-btn {
  color: var(--primary-color) !important;
  border-color: var(--red) !important;
}

.cancel-btn {
  color: var(--red) !important;
  border-color: var(--red) !important;
  font-size: 14px !important;
}

.MuiTextField-root {
  box-shadow: var(--main-box-shadow);
  border-radius: 5px !important;
  font-family: 'Poppins' !important;
}

.MuiOutlinedInput-input {
  font-family: 'Poppins' !important;
}

.MuiTypography-body1 {
  font-family: 'Poppins' !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color) !important;
  border-radius: 5px !important;
}

.MuiInputBase-input {
  font-family: 'Poppins' !important;
  font-size: 16px !important;
}

.MuiFormLabel-root {
  color: var(--primary-color) !important;
  font-family: 'Poppins' !important;
}

.MuiSelect-icon {
  color: var(--primary-color) !important;
  font-family: 'Poppins' !important;
}

.MuiSelect-select {
  font-family: 'Poppins' !important;
}

button.tab {
  background: white !important;
  color: var(--primary-color);
  border: 1px solid;
  font-family: 'Poppins';
  text-transform: capitalize;
}

button.active-tab {
  background: var(--primary-color) !important;
  color: white;
  font-family: 'Poppins' !important;
  text-transform: capitalize;
}

.MuiRadio-root {
  color: var(--primary-color) !important;
  font-family: 'Poppins' !important;
}

.MUIDataTableBodyCell-root-781 {
  text-align: 'center';
}